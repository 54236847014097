import { routes } from 'router';

export interface MenuItem {
  title: string;
  route: string;
}
export const menu: MenuItem[] = [
  {
    title: 'About us',
    route: routes.ABOUT,
  },
  {
    title: 'Payment',
    route: routes.ABOUT,
  },
  {
    title: 'Delivery',
    route: routes.ABOUT,
  },
];
