import * as React from 'react';
import { Box, Typography, IconButton, Divider } from '@mui/material';
import { LanguageSwitch, ThemeSwitch } from 'components';
import { useTranslation } from 'next-i18next';
import { Close as CloseIcon } from '@mui/icons-material';

type Props = {
  onClose: () => void;
};

const Settings = ({ onClose }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: 310,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Box
        sx={{
          mt: 2,
          px: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ textTransform: 'capitalize' }}>
          {t('settings')}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography sx={{ textTransform: 'capitalize' }}>
          {t('theme')}
        </Typography>
        <ThemeSwitch />
      </Box>
      <Divider />
      <Box sx={{ px: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography sx={{ textTransform: 'capitalize' }}>
          {t('language')}
        </Typography>
        <LanguageSwitch />
      </Box>
    </Box>
  );
};
export default Settings;
