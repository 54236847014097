import * as React from 'react';
import LogoImage from './LogoImage';
import { routes } from '../../router';
import Link from 'next/link';
import { Link as MuiLink } from '@mui/material';

type Props = {
  size?: number;
};

const Logo = ({ size }: Props) => {
  return (
    <Link href={routes.ROOT} passHref>
      <MuiLink
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <LogoImage aria-label="Loskut" size={size} />
      </MuiLink>
    </Link>
  );
};
export default Logo;
