import { buildImagePath } from 'utils';

type Album = {
  title: string;
  description: string;
  link: string;
  coverImage: string;
};

export const albums: Album[] = [
  {
    title: 'All albums',
    description: 'All albums that are presented in our store',
    link: 'https://www.flickr.com/photos/loskutcom/albums',
    coverImage: buildImagePath('albums/all.jpg'),
  },
  {
    title: 'Light fabrics',
    description:
      'Cotton, linen, silk, hb, satin, satin, staple, viscose, guipure, matting ...',
    link: 'https://www.flickr.com/photos/loskutcom/albums/72157669157050863',
    coverImage: buildImagePath('albums/summer.jpg'),
  },
  {
    title: 'Warm fabrics',
    description: 'Costume wool, coats, fur, velvet, jeans, cashmere ...',
    link: 'https://www.flickr.com/photos/loskutcom/albums/72157669157050863',
    coverImage: buildImagePath('albums/winter.jpg'),
  },
  {
    title: 'Jersey',
    description:
      'Knitwear hb, wool knitwear, fleece, cooler, interlock, velor, velsoft ...',
    link: 'https://www.flickr.com/photos/loskutcom/albums/72157672037215806',
    coverImage: buildImagePath('albums/jersey.jpg'),
  },
  {
    title: 'Furniture fabrics',
    description:
      'Fabrics for waist, tapestry, velor, flock, upholstery, gunny ...',
    link: 'https://www.flickr.com/photos/loskutcom/albums/72157675693816833',
    coverImage: buildImagePath('albums/furniture.jpg'),
  },
  {
    title: 'Lining fabrics',
    description: 'Lining viscose, synthetic lining, raincoat fabric, nylon ...',
    link: 'https://www.flickr.com/photos/loskutcom/albums/72157672128151325',
    coverImage: buildImagePath('albums/lining.jpg'),
  },
];
