import * as React from 'react';

import { default as NextImage, ImageProps } from 'next/image';

type Props = ImageProps;
const Image = ({ src, width, height, ...rest }: Props) => {
  return (
    <NextImage
      placeholder="blur"
      blurDataURL="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      src={src}
      width={width}
      height={height}
      {...rest}
    />
  );
};

export default Image;
