import * as React from 'react';
import { Container, Toolbar, Box } from '@mui/material';
import { Footer, Header, ScrollToTop } from 'components';

type RootLayoutProps = {
  children: React.ReactElement | React.ReactElement[];
};

const RootLayout = (props: RootLayoutProps) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header />
      <Toolbar id="back-to-top-anchor" />
      <Container maxWidth="lg">{props.children}</Container>
      <ScrollToTop />
      <Footer sx={{ marginTop: 'auto' }} />
    </Box>
  );
};

export default RootLayout;
