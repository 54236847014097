import { AppBar, Toolbar, Box, Container } from '@mui/material';
import { Logo, Menu, PhoneNumbers, SettingsButton } from 'components';
import { useContext } from 'react';
import { ThemeContext } from 'contexts';
import * as React from 'react';

const Header = () => {
  const { isLightTheme } = useContext(ThemeContext);

  return (
    <AppBar
      color="inherit"
      variant="outlined"
      elevation={0}
      sx={{
        backdropFilter: 'blur(10px)',
        background: isLightTheme
          ? 'rgba(255,255,255,0.8)'
          : 'rgba(18,18,18,0.8)',
      }}
    >
      <Toolbar>
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            px: [0, 0, 0, 3],
          }}
        >
          <Logo size={40} />
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Menu />
            <PhoneNumbers />
          </Box>

          <Box
            sx={{
              marginLeft: 'auto',
            }}
          >
            <SettingsButton />
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
