import * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Box, Fab, Fade, Tooltip, useScrollTrigger } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollTop = () => {
  const { t } = useTranslation();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        <Tooltip title={t('Scroll to top')}>
          <Fab
            aria-label="scroll back to top"
            color="info"
            onClick={handleClick}
          >
            <KeyboardArrowUp />
          </Fab>
        </Tooltip>
      </Box>
    </Fade>
  );
};

export default ScrollTop;
