import { buildImagePath } from 'utils';

export type Phone = {
  value: string;
  src: string;
  alt: string;
};
export const phones: Phone[] = [
  {
    value: '+380972204100',
    src: buildImagePath('/logos/kyivstar.svg'),
    alt: 'Kyivstar',
  },
];
