import * as React from 'react';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { LanguageContext } from 'contexts';
import { useTranslation } from 'next-i18next';
import { Language } from 'contexts/LanguageContext/helpers';

const LanguageSwitch = () => {
  const { t } = useTranslation();
  const { language, setLanguage } = React.useContext(LanguageContext);

  const handleChange = (value: Language) => {
    setLanguage(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={language}
      exclusive
      onChange={(_, value) => handleChange(value)}
    >
      <ToggleButton
        value={Language.UA}
        aria-label="ukrainian"
        sx={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}
      >
        🇺🇦
        <Typography sx={{ textTransform: 'capitalize', ml: 1 }}>
          {t('ukrainian')}
        </Typography>
      </ToggleButton>
      <ToggleButton
        value={Language.EN}
        aria-label="english"
        sx={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}
      >
        🇬🇧
        <Typography sx={{ textTransform: 'capitalize', ml: 1 }}>
          {t('english')}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LanguageSwitch;
