import * as React from 'react';
import {
  IconButton,
  SwipeableDrawer,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';
import { SettingsOutlined as SettingsIcon } from '@mui/icons-material';
import { Settings } from 'components';
import { useTranslation } from 'next-i18next';

const SettingsButton = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod|Safari/.test(navigator.userAgent);

  return (
    <Box>
      <Tooltip
        title={
          <Typography sx={{ textTransform: 'capitalize' }}>
            {t('settings')}
          </Typography>
        }
      >
        <IconButton onClick={handleClick} aria-label="Setting button">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={isOpen}
        onClick={(event) => event.stopPropagation()}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Settings onClose={handleClose} />
      </SwipeableDrawer>
    </Box>
  );
};
export default SettingsButton;
