import * as React from 'react';
import { defineIsTouchDevice } from 'utils';

type Props = React.HTMLAttributes<SVGSVGElement> & {
  size?: number;
};
type Colors = string[];
const LogoImage = ({ size = 50, ...rest }: Props) => {
  const [pause, setPause] = React.useState<boolean>(true);
  const [diff, setDiff] = React.useState<number>(0);
  const colors: Colors = [
    '#b49db7',
    '#7e953d',
    '#ffd475',
    '#febd3b',
    '#f39d9c',
    '#ec818b',
    '#9dadce',
    '#7899c6',
    '#00B2EE',
    '#91b342',
    '#715786',
  ];
  const [isTouchDevice, setIsTouchDevice] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (pause) {
      return setDiff(0);
    }
    const interval = setInterval(() => {
      setDiff(diff + 6);
    }, 10);
    return () => clearInterval(interval);
  }, [diff, pause]);

  React.useEffect(() => {
    setIsTouchDevice(defineIsTouchDevice());
  }, []);

  const handleOnMouseEnter = () => {
    if (isTouchDevice) return;
    setPause(false);
  };
  const handleOnMouseLeave = () => {
    if (isTouchDevice) return;
    setPause(true);
  };

  return (
    <>
      <svg
        {...rest}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={size}
        height={size}
      >
        {colors.map((color, index) => (
          <rect
            key={color}
            fill={color}
            width="25%"
            height="43%"
            style={{
              transform: `translate(50%, 50%) rotate(${index * 36 + diff}deg)`,
              transition: 'transform 0.01s ease-in-out',
              willChange: 'transform',
            }}
          />
        ))}
        <circle
          cx="50%"
          cy="50%"
          stroke="black"
          strokeWidth={0.5}
          strokeOpacity={0.8}
          r={`calc(12.5/50 * 100%)`}
          fill="#0066cc"
        />
        <path
          d={`M${(size / 2 - 0.5) / 2},${size / 2} a1,1 0 0,0 ${
            size / 2 - 0.5
          },0`}
          fill="#ffcc00"
        />
      </svg>
    </>
  );
};
export default LogoImage;
