import * as React from 'react';
import { Container, ContainerProps, Typography } from '@mui/material';

type Props = ContainerProps;

const Footer = (props: Props) => {
  return (
    <Container {...props} component="footer">
      <Typography>Footer</Typography>
    </Container>
  );
};

export default Footer;
