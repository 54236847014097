import * as React from 'react';
import { menu } from './hepler';
import Link from 'next/link';
import { Link as MuiLink, List, ListItem } from '@mui/material';
import { useTranslation } from 'next-i18next';

const Menu = () => {
  const { t } = useTranslation();
  return (
    <List sx={{ display: 'flex', alignItems: 'center' }}>
      {menu.map((item) => (
        <ListItem key={item.title}>
          <Link href={item.route}>
            <MuiLink
              underline="none"
              color="inherit"
              variant="body2"
              sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              href={item.route}
            >
              {t(item.title)}
            </MuiLink>
          </Link>
        </ListItem>
      ))}
    </List>
  );
};

export default Menu;
