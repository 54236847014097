import * as React from 'react';
import {
  WbSunny as WbSunnyIcon,
  NightsStay as NightsStayIcon,
} from '@mui/icons-material';

import { ThemeContext } from 'contexts';
import {
  PaletteMode,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useTranslation } from 'next-i18next';

const ThemeToggleButton = () => {
  const { t } = useTranslation();
  const { themeMode, setThemeMode } = React.useContext(ThemeContext);
  const handleChange = (value: PaletteMode) => {
    if (value) setThemeMode(value);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={themeMode}
      exclusive
      onChange={(_, value) => handleChange(value)}
    >
      <ToggleButton
        value="light"
        aria-label="light"
        sx={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}
      >
        <WbSunnyIcon />
        <Typography sx={{ textTransform: 'capitalize', ml: 1 }}>
          {t('light')}
        </Typography>
      </ToggleButton>
      <ToggleButton
        value="dark"
        aria-label="dark"
        sx={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}
      >
        <NightsStayIcon />
        <Typography sx={{ textTransform: 'capitalize', ml: 1 }}>
          {t('dark')}
        </Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
export default ThemeToggleButton;
