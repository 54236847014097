import * as React from 'react';
import { List, ListItem } from '@mui/material';
import Phone from './Phone';
import { phones } from './helpers';

const PhoneNumbers = () => {
  return (
    <List>
      {phones.map((phone) => (
        <ListItem key={phone.value}>
          <Phone {...phone} />
        </ListItem>
      ))}
    </List>
  );
};

export default PhoneNumbers;
