import * as React from 'react';
import { Box, Link } from '@mui/material';
import { Phone } from './helpers';
import Image from '../Image';
type Props = Phone;
const Phone = ({ value, src, alt }: Props) => {
  return (
    <Box sx={{}}>
      <Image src={src} width={10} height={10} alt={alt} />
      <Link underline="none" href={`tel:${value}`}>
        {value}
      </Link>
    </Box>
  );
};

export default Phone;
