import Box from '@mui/material/Box';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { RootLayout } from 'layouts';
import Head from 'next/head';
import { Albums } from 'components';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <title>{t('Main Page')}</title>
      </Head>
      <RootLayout>
        <Box>
          <Box
            sx={{
              my: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Albums />
          </Box>
        </Box>
      </RootLayout>
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: string }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'], null)),
    },
  };
};

export default Home;
