import * as React from 'react';
import { Card, Box, CardContent, Typography, Link } from '@mui/material';
import { albums } from './helper';
import { useTranslation } from 'next-i18next';
import { Image } from 'components';

const Albums = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      {albums.map(({ title, description, coverImage, link }) => (
        <Card
          key={title}
          sx={{
            width: 300,
            display: 'flex',
            flexDirection: 'column',
            '&:hover': {
              boxShadow:
                'rgb(154 160 185 / 5%) 0px 5px 10px, rgb(166 173 201 / 20%) 0px 15px 40px',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
          }}
          variant="outlined"
        >
          <Link
            underline="none"
            href={link}
            color="inherit"
            target="_blank"
            variant="body2"
            rel="noopener"
          >
            <Image
              src={coverImage}
              height={300}
              width={300}
              alt={title}
              objectFit={'cover'}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {t(title)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t(description)}
              </Typography>
            </CardContent>
          </Link>
        </Card>
      ))}
    </Box>
  );
};

export default Albums;
